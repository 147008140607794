import React from 'react'
import { formatTimeInto12Hours, isMobileScreenSize } from '../../helpers'
import {
  StyledScrubberContainer,
  StyledScrubberHelp,
  StyledScrubberThumb,
  StyledScrubberThumbImage,
  StyledScrubberThumbTime,
  StyledScrubberTrack,
} from './TimelineScrubber.styled'
import { isToday, parse } from 'date-fns'
import { SIDEBAR_WIDTH } from '../Schedule/Schedule'

const HOUR_WIDTH_IN_PX = 300

type TimelineScrubberProps = {
  isLoading: boolean
  scrollBoxRef: React.RefObject<HTMLDivElement> | undefined
  /**
   * Time of first event e.g. "2022-07-29T07:30:00Z"
   */
  firstEventOfDay: string
}
type TimelineScrubberState = {
  buttonScrollPosition: string
  isMouseDown: boolean
  isLoading: boolean
  scrubberClickOffsetX: number
  scrolledTimeValue: string
  hasUserScrolledManually: boolean
}

const scrubberDots = require('./assets/scrubberDots.png')

class TimelineScrubber extends React.Component<TimelineScrubberProps, TimelineScrubberState> {
  static displayName = 'TimelineScrubber'
  scrubberContainerRef: React.RefObject<HTMLDivElement>
  scrollBoxRef: React.RefObject<HTMLDivElement> | undefined
  scrubberThumbRef: React.RefObject<HTMLDivElement>

  constructor(props: TimelineScrubberProps) {
    super(props)
    this.state = {
      isMouseDown: false,
      buttonScrollPosition: '0%',
      isLoading: false,
      scrubberClickOffsetX: 0,
      scrolledTimeValue: '12:00am',
      hasUserScrolledManually: false,
    }
    this.handleMouseDown = this.handleMouseDown.bind(this)
    this.handleMouseMove = this.handleMouseMove.bind(this)
    this.handleMouseUp = this.handleMouseUp.bind(this)
    this.handleTouchStart = this.handleTouchStart.bind(this)
    this.handleTouchEnd = this.handleTouchEnd.bind(this)
    this.handleTouchMove = this.handleTouchMove.bind(this)
    this.handleScrollBoxHorizontalScroll = this.handleScrollBoxHorizontalScroll.bind(this)
    this.scrubberContainerRef = React.createRef()
    this.scrollBoxRef = props.scrollBoxRef
    this.scrubberThumbRef = React.createRef()
  }

  componentDidUpdate(prevProps: TimelineScrubberProps, prevState: TimelineScrubberState) {
    if (prevProps.scrollBoxRef !== this.props.scrollBoxRef) {
      this.scrollBoxRef = this.props.scrollBoxRef
      if (this.scrollBoxRef?.current) {
        this.scrollBoxRef.current?.addEventListener('scroll', this.handleScrollBoxHorizontalScroll)
      }
    }

    if (prevProps.firstEventOfDay !== this.props.firstEventOfDay) {
      // day changed, vertical scroll to top of ScrollBox
      if (this.scrollBoxRef?.current) {
        this.scrollBoxRef.current.scrollTop = 0
      }
      // horizontal scroll to current first match in day
      const now = new Date()
      this.scrollToTime(this.props.firstEventOfDay)
    }
  }

  scrollToTime(time: string) {
    if (time !== '' && this.scrubberContainerRef.current) {
      // TODO: store hour pixel width in somekind of global config
      const widthOfHour = HOUR_WIDTH_IN_PX
      const dateToScrollTo = new Date(time)
      const timeAsNumber = dateToScrollTo.getHours() + dateToScrollTo.getMinutes() / 60
      const pixelsToScroll = timeAsNumber * widthOfHour
      if (this.scrollBoxRef && this.scrollBoxRef.current && this.scrubberThumbRef.current) {
        // scrolls so event starts right next to the pictogram
        this.scrollBoxRef.current.scrollLeft = pixelsToScroll
      }
    }
  }

  getTimeFromScrollLeft(scrollLeft: number) {
    const widthOfHour = HOUR_WIDTH_IN_PX
    const timeAsNumber = scrollLeft / widthOfHour
    const hours = Math.floor(timeAsNumber)
    const hoursWithPadding = hours < 10 ? `0${hours}` : `${hours}`
    const minutes = Math.floor((timeAsNumber % 1) * 60)
    const minutesWithPadding = minutes < 10 ? `0${minutes}` : `${minutes}`
    const time = hoursWithPadding + ':' + minutesWithPadding
    const formatted12HourTime = formatTimeInto12Hours(time)
    return formatted12HourTime
  }

  calculatePercentageValueForButtonPosition(clientX: number, buttonWidth: number) {
    if (
      clientX - this.state.scrubberClickOffsetX >
      (this.scrubberContainerRef.current?.offsetWidth as number) - buttonWidth
    ) {
      return `calc(100% - ${buttonWidth}px)`
    }
    if (clientX - this.state.scrubberClickOffsetX < 0) {
      return '0%'
    }

    const percentageAsNumber = String(
      Math.floor(
        ((clientX - this.state.scrubberClickOffsetX) /
          (this.scrubberContainerRef.current?.offsetWidth as number)) *
          100,
      ),
    )
    return `${percentageAsNumber}%`
  }

  /**
   * Percentage value of thumb position relative to total scrolling track available
   * @param clientX
   * @param scrollWidthOffset
   * @returns
   */
  calculatePercentageValueForScrolling(clientX: number, scrollWidthOffset: number) {
    if (
      clientX - this.state.scrubberClickOffsetX >
      (this.scrubberContainerRef.current?.clientWidth as number) - scrollWidthOffset
    ) {
      return 1
    }
    if (clientX - this.state.scrubberClickOffsetX < 0) {
      return 0
    }
    return (
      (clientX - this.state.scrubberClickOffsetX) /
      ((this.scrubberContainerRef.current?.clientWidth as number) - scrollWidthOffset)
    )
  }

  resetScroll() {
    if (this.scrollBoxRef?.current) {
      this.scrollBoxRef.current.scrollLeft = 0
    }

    this.setState({
      buttonScrollPosition: '0%',
      scrolledTimeValue: '12:00am',
      scrubberClickOffsetX: 0,
    })
  }

  // Format time for displaying in scrubber button
  getScrolledTimeFromPercentage(scrollPercentageAsNumber: number) {
    // calculate hours
    if (scrollPercentageAsNumber > 1) {
      scrollPercentageAsNumber = 1
    }
    const percentOfHours = scrollPercentageAsNumber * 24
    const hoursPart = Math.floor(percentOfHours)
    const hoursPartWithPadding = hoursPart < 10 ? `0${hoursPart}` : `${hoursPart}`
    // calculate minutes
    const fractionalPart = percentOfHours % 1
    const minutesPart = Math.floor(fractionalPart * 60)
    const minutesWithPadding = minutesPart < 10 ? `0${minutesPart}` : `${minutesPart}`
    // format
    const time = hoursPartWithPadding + ':' + minutesWithPadding

    // fix for auto scrolling to beginning of schedule(12:00am) when time is midnight
    let formatted12HourTime
    if (time === '24:00') {
      formatted12HourTime = formatTimeInto12Hours('23:59')
    } else {
      formatted12HourTime = formatTimeInto12Hours(time)
    }
    return formatted12HourTime
  }

  handleMouseMove(e: MouseEvent) {
    if (this.state.isMouseDown && this.state.scrubberClickOffsetX > 0) {
      this.updateScrollState(e.clientX)
      this.setState({
        hasUserScrolledManually: true,
      })
    }
  }

  handleMouseDown(e: React.MouseEvent<HTMLDivElement>) {
    this.updateElementClickOffset(e.pageX, e.currentTarget.offsetLeft)
    document.addEventListener('mousemove', this.handleMouseMove)
    document.addEventListener('mouseup', this.handleMouseUp)
  }

  handleMouseUp() {
    this.setState({
      isMouseDown: false,
      scrubberClickOffsetX: 0,
    })
    document.removeEventListener('mousemove', this.handleMouseMove)
    document.removeEventListener('mouseup', this.handleMouseUp)
  }

  /**
   * Calculates where the scroll button was clicked relative to it's beginning
   * @param clickPosition how many pixels clicked/tapped relative to left of screen
   * @param elementBeginningPosition how many pixels element begins relative to left of screen
   */
  updateElementClickOffset(clickPositionX: number, elementBeginningPositionX: number) {
    const scrubberClickOffset = clickPositionX - elementBeginningPositionX
    this.setState({
      isMouseDown: true,
      scrubberClickOffsetX: scrubberClickOffset,
    })
  }

  handleTouchStart(e: React.TouchEvent<HTMLDivElement>) {
    this.updateElementClickOffset(e.touches[0].pageX, e.currentTarget.offsetLeft)
    document.addEventListener('touchmove', this.handleTouchMove)
    document.addEventListener('touchend', this.handleTouchEnd)
  }

  handleTouchEnd() {
    this.setState({
      isMouseDown: false,
      scrubberClickOffsetX: 0,
    })
    document.removeEventListener('touchmove', this.handleTouchMove)
    document.removeEventListener('touchend', this.handleTouchEnd)
  }

  updateScrubberThumbPosition(positionX: number) {
    if (this.scrubberThumbRef.current) {
      const calculatedPercentage = this.calculatePercentageValueForButtonPosition(
        positionX,
        this.scrubberThumbRef.current.clientWidth,
      )
      this.setState({
        buttonScrollPosition: calculatedPercentage,
      })
    }
  }

  /**
   * Used when scrolling using the scroll time button
   * @param scrollPercentageAsNumber
   */
  updateScrubberThumbTime(scrollPercentageAsNumber: number) {
    this.setState({ scrolledTimeValue: this.getScrolledTimeFromPercentage(scrollPercentageAsNumber) })
  }

  /**
   * Used by scroll event handler
   * @param scrollLeft
   */
  updateScrubberThumbTimeBasedOnScrollLeft(scrollLeft: number) {
    this.setState({ scrolledTimeValue: this.getTimeFromScrollLeft(scrollLeft) })
  }

  /**
   * Handles scrolling using the scrubber
   * @param positionX pixel value of how much the mouse/finger has moved while scrolling
   */
  updateScrollState(positionX: number) {
    if (this.scrubberThumbRef.current) {
      // update the scroll position of the scrollable schedule container
      if (this.scrollBoxRef?.current && this.scrubberContainerRef.current) {
        this.updateScrubberThumbPosition(positionX)

        const scrollPercentageWithoutOffsetForWidthOfVisibleContent =
          this.calculatePercentageValueForScrolling(positionX, this.scrubberThumbRef.current.clientWidth)
        this.updateScrubberThumbTime(scrollPercentageWithoutOffsetForWidthOfVisibleContent)

        // horizontal scroll to calculated time inside scroll container
        const existingScrollTimeAsDate = parse(this.state.scrolledTimeValue, 'h:mma', new Date())
        this.scrollToTime(existingScrollTimeAsDate.toISOString())
      }
    }
  }

  handleTouchMove(e: TouchEvent) {
    if (this.state.isMouseDown && this.state.scrubberClickOffsetX > 0) {
      this.updateScrollState(e.touches[0].clientX)
      this.setState({
        hasUserScrolledManually: true,
      })
    }
  }

  /**
   * Handles scroll event when scrolling inside the schedule by swiping or after updating scrollLeft in scrollToTime
   * @param e
   */
  handleScrollBoxHorizontalScroll(e: Event) {
    if (this.scrubberContainerRef.current && this.scrubberThumbRef.current && !this.state.isMouseDown) {
      const scrollLeft = (e.target as HTMLDivElement).scrollLeft
      // TODO: move these const values to global config
      const verticalScrollBarWidth = 0
      const sidebarWidth = SIDEBAR_WIDTH
      const widthOfHour = HOUR_WIDTH_IN_PX
      const visibleWidthOfScrollableContainer =
        this.scrubberContainerRef.current.clientWidth - sidebarWidth - verticalScrollBarWidth

      // calculate scroll thumb pixel position proportionaly to whole scrollable container
      const maximumScrollLeftValue = 24 * widthOfHour - visibleWidthOfScrollableContainer
      const percentageOfMaximumScrollLeft = scrollLeft / maximumScrollLeftValue
      const remainingScrubberOffset =
        (visibleWidthOfScrollableContainer / maximumScrollLeftValue) *
        (this.scrubberContainerRef.current.clientWidth - this.scrubberThumbRef.current.clientWidth)
      const thumbPixelPositionRelativeToPortionOfScrolledScrollBox =
        percentageOfMaximumScrollLeft *
        (this.scrubberContainerRef.current.clientWidth -
          this.scrubberThumbRef.current.clientWidth -
          remainingScrubberOffset)

      this.updateScrubberThumbPosition(thumbPixelPositionRelativeToPortionOfScrolledScrollBox)
      this.updateScrubberThumbTimeBasedOnScrollLeft(scrollLeft)

      this.setState({
        hasUserScrolledManually: true,
      })
    }
  }

  render() {
    return (
      <StyledScrubberContainer ref={this.scrubberContainerRef}>
        <StyledScrubberTrack />
        <StyledScrubberThumb
          onMouseDown={this.handleMouseDown}
          onMouseUp={this.handleMouseUp}
          percentageValue={this.state.buttonScrollPosition}
          ref={this.scrubberThumbRef}
          onTouchStart={this.handleTouchStart}
          onTouchEnd={this.handleTouchEnd}
        >
          <StyledScrubberThumbTime>{this.state.scrolledTimeValue}</StyledScrubberThumbTime>
          <StyledScrubberThumbImage src={scrubberDots} />
        </StyledScrubberThumb>
      </StyledScrubberContainer>
    )
  }
}

export default TimelineScrubber
