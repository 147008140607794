import styled from '@emotion/styled'
import { TimelineBox, TimelineTime, TimelineWrapper } from 'planby'

export const StyledTimelineWrapper = styled(TimelineWrapper)(() => ({
  zIndex: 9999,
  '@media(min-width: 768px)': {
    height: '70px',
  },
}))

export const StyledTimelineDivider = styled('div')(({ theme }) => ({
  width: '100%',
  height: '1px',
  background: theme.timelineDivider,
  position: 'absolute',
  top: '50px',
  '@media(min-width: 768px)': {
    top: '54px',
  },
}))

export const StyledTimelineTime = styled(TimelineTime)(() => ({
  fontSize: '12px',
  fontWeight: 500,
}))

export const StyledTimelineBox = styled(TimelineBox)(() => ({
  fontSize: '12px',
  top: '6px',
}))
