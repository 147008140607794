import styled from '@emotion/styled'
import { ProgramBox, ProgramContent, ProgramStack, ProgramText, ProgramTitle } from 'planby'
import { Placement } from '../../helpers'

export interface StyledChannelLogoProps {
  isEnded: boolean
  isLive: boolean
  primaryColor: string
  secondaryColor: string
  placement: Placement
}
export const StyledProgramContent = styled(ProgramContent)<StyledChannelLogoProps>(
  ({ secondaryColor, primaryColor, placement }) => ({
    borderRadius: 0,
    background: placement === '7plus' ? primaryColor + '40' : secondaryColor,
    padding: '8px 15px',
    '&:hover': {
      background: primaryColor + (placement === '7plus' ? 'B3' : 'B3'),
    },
    textAlign: 'start',
    transition: 'all 0.3s ease-in-out',
  }),
)

export const StyledEmptyProgramContent = styled('div')(({ theme }) => ({
  borderRadius: 0,
  background: theme.eventBox.fillerBackground,
  padding: '8px 15px',
  '&:hover': {
    background: theme.eventBox.fillerBackground,
  },
  opacity: 'unset',
  textAlign: 'start',
  '@media(min-width: 768px)': {
    padding: '10px 15px',
  },
  height: '100%',
  cursor: 'default',
}))

interface StyledProgramBoxProps {
  isFiller: boolean
}

export const StyledProgramBox = styled(ProgramBox)<StyledProgramBoxProps>(({ isFiller }) => ({
  padding: '2px',
  paddingTop: '2px',
  paddingBottom: '3px',
  a: {
    textDecoration: 'none',
  },
  cursor: isFiller ? 'default' : 'pointer',
}))

export const StyledProgramStack = styled(ProgramStack)(() => ({
  overflow: 'visible',
  marginTop: '4px',
}))

export const StyledProgramTime = styled('span')(({ theme }) => ({
  fontWeight: 600,
  color: theme.eventBox.text,
  fontSize: '10px',
  textTransform: 'lowercase',
}))

export const StyledProgramTitle = styled(ProgramTitle)(({ theme }) => ({
  fontWeight: 600,
  color: theme.eventBox.text,
  marginBottom: '4px',
  fontSize: '12px',
  textTransform: 'uppercase',
}))

export const StyledProgramDescription = styled(ProgramText)(({ theme }) => ({
  color: theme.eventBox.textDescription,
  whiteSpace: 'normal',
  display: '-webkit-box',
  lineClamp: 2,
  WebkitLineClamp: '2',
  WebkitBoxOrient: 'vertical',
  fontSize: '11px',
  marginTop: '7px',
  '@media(min-width: 768px)': {
    marginTop: '9px',
  },
}))

export const StyledLiveText = styled('span')(() => ({
  fontSize: '10px',
  marginRight: '6px',
  fontWeight: 600,
  '@media(min-width: 768px)': {
    fontSize: '11px',
  },
}))

export const StyledStreamIcon = styled('img')(() => ({
  height: '20px',
  marginLeft: '12px',
}))
