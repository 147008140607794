import { useMemo, useEffect } from 'react'
import { Channel, ChannelWithMeta, EpgSchedule, getPlacement, isMobileScreenSize } from '../../helpers'
import { useEpg, Epg, Layout } from 'planby'
import { Timeline } from '../Timeline/Timeline'
import { ChannelIcon } from '../ChannelIcon/ChannelIcon'
import { ChannelProgram } from '../ChannelProgram/ChannelProgram'
import { StyledLoaderContainer, StyledScheduleContainer } from './Schedule.styled'
import { useTheme } from '@emotion/react'

type ScheduleProps = {
  startDate: string
  isLoading: boolean
  /**
   * Passed to TimelineScrubber for handling scrolling
   */
  setScrollBoxRef: React.Dispatch<React.SetStateAction<React.RefObject<HTMLDivElement> | undefined>>
  epg: EpgSchedule[]
  channels: ChannelWithMeta[]
}

export const SIDEBAR_WIDTH = 87

const applyStylesToScrollBox = (ref: React.RefObject<HTMLDivElement>) => {
  if (ref.current) {
    ref.current.style.overflowX = 'scroll'
    ref.current.style.scrollBehavior = 'auto'
    ref.current.style.height = '878px'
  }
}

function Schedule(props: ScheduleProps) {
  const { startDate, isLoading, setScrollBoxRef, epg, channels } = props

  const channelsData = useMemo(() => channels, [channels])
  const epgData = useMemo(() => epg, [epg])
  const theme = useTheme()

  const { getEpgProps, getLayoutProps } = useEpg({
    channels: channelsData,
    epg: epgData,
    startDate,
    dayWidth: 7200,
    itemHeight: 79,
    isLine: true,
    theme: theme,
    isBaseTimeFormat: true,
    sidebarWidth: SIDEBAR_WIDTH,
  })

  const { ref } = getLayoutProps()

  useEffect(() => {
    if (ref.current) {
      setScrollBoxRef(ref)
      applyStylesToScrollBox(ref)
    }
  }, [ref])

  return (
    <StyledScheduleContainer>
      <Epg
        isLoading={isLoading}
        {...getEpgProps()}
        loader={
          <StyledLoaderContainer>
            <div className="lds-default">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </StyledLoaderContainer>
        }
      >
        <Layout
          {...getLayoutProps()}
          renderProgram={({ program, isBaseTimeFormat }) => (
            <ChannelProgram
              key={program.data.id}
              program={program}
              isBaseTimeFormat={isBaseTimeFormat}
              onClick={() => {}}
            />
          )}
          renderChannel={({ channel }) => <ChannelIcon key={channel.uuid} channel={channel} />}
          renderTimeline={(props) => <Timeline {...props} />}
        />
      </Epg>
    </StyledScheduleContainer>
  )
}

export default Schedule
