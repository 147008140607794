import { ProgramFlex, useProgram } from 'planby'
import { ProgramItem } from 'planby/dist/Epg/helpers/types'
import { getPlacement, TIME_FORMAT } from '../../helpers'
import { getLinkUrlFromMatch } from '../../helpers/channelUrlData'
import {
  StyledEmptyProgramContent,
  StyledLiveText,
  StyledProgramBox,
  StyledProgramContent,
  StyledProgramDescription,
  StyledProgramStack,
  StyledProgramTime,
  StyledProgramTitle,
  StyledStreamIcon,
} from './ChannelProgram.styled'
import { isPast } from 'date-fns'
import { getPrimaryColorFromChannel, getSecondaryColorFromChannel } from '../../helpers/channels'

type ChannelProgramProps = {
  program: ProgramItem
  isBaseTimeFormat: boolean
  onClick: () => void
}

const getStreamIconFromMatchUrl = (matchUrl: string) => {
  if (matchUrl.includes('optus')) {
    return require('./assets/optus.png')
  } else {
    return require('./assets/7plus.png')
  }
}

export const ChannelProgram = ({ program, onClick, isBaseTimeFormat }: ChannelProgramProps) => {
  const { styles, formatTime, isLive, isMinWidth } = useProgram({
    program,
    isBaseTimeFormat,
  })

  const { data } = program
  const { image, title, since, till, description, channelUuid } = data

  const handleItemClick = () => {
    onClick()
  }

  const timeFormat = isBaseTimeFormat ? TIME_FORMAT.BASE_HOURS_TIME : TIME_FORMAT.HOURS_MIN

  const formattedDescription = description.replaceAll('\n', ' | ')

  const matchUrl = getLinkUrlFromMatch(program.data.id, getPlacement())

  return (
    <StyledProgramBox
      width={styles.width}
      style={styles.position}
      title={title === 'filler' ? undefined : formattedDescription}
      isFiller={title === 'filler'}
    >
      {title === 'filler' ? (
        <StyledEmptyProgramContent></StyledEmptyProgramContent>
      ) : (
        <a href={matchUrl} target="_blank" rel="noopener noreferrer">
          <StyledProgramContent
            width={styles.width}
            isLive={isLive}
            onClick={handleItemClick}
            isEnded={isPast(new Date(till))}
            primaryColor={getPrimaryColorFromChannel(channelUuid)}
            secondaryColor={getSecondaryColorFromChannel(channelUuid)}
            placement={getPlacement()}
          >
            <ProgramFlex>
              <StyledProgramStack>
                <StyledProgramTitle>{title}</StyledProgramTitle>
                <StyledProgramTime>
                  {formatTime(since, timeFormat).replace('AM', '').replace('PM', '')}-
                  {formatTime(till, timeFormat)}
                </StyledProgramTime>
                <StyledProgramDescription>{formattedDescription}</StyledProgramDescription>
              </StyledProgramStack>
              <StyledStreamIcon
                src={getStreamIconFromMatchUrl(matchUrl)}
                alt={(matchUrl.includes('optus') ? 'Optus' : '7plus') + 'logo'}
              />
            </ProgramFlex>
          </StyledProgramContent>
        </a>
      )}
    </StyledProgramBox>
  )
}
