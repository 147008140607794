import { format, parseISO, isSameDay, subMinutes, parse, addMinutes } from 'date-fns'
import { channels } from './channels'

export type EpgSchedule = {
  id: string
  description: string
  title: string
  isYesterday?: boolean
  since: string
  till: string
  channelUuid: string
  image: string
}

export type Channel = {
  uuid: string
  type?: string
  title: string
  country?: string
  provider?: number
  logo: string
  year?: number
  city?: string
  stadium?: string
}

export type ChannelWithMeta = Channel & {
  color: string
  secondaryColor: string
  city: string
  stadium: string
  hasEventForCurrentDate?: boolean
}

interface OptaFixturesMatchData {
  MatchInfo: {
    Date: string
    TZ: string
    '@attributes': {
      GroupName: string
      MatchDay: string
      MatchType: string
      Period: string
      RoundNumber: string
      RoundType: string
      TimeFrameLengthId: string
      Venue_id: string
    }
  }
  // venue in first array element, city in second
  Stat: {
    '@value': string
    '@attributes': {
      Type: 'Venue' | 'City' | 'Neutral'
    }
  }[]
  TeamData: {
    '@value': ''
    '@attributes': {
      Side: 'Home' | 'Away'
      // team id
      TeamRef: string
    }
  }[]
  '@attributes': {
    detail_id: string
    last_modified: string
    timestamp_accuracy_id: string
    // internal second match id
    uID: string
  }
}

interface OptaFixturesTeamData {
  Name: string
  '@attributes': {
    uID: string
  }
}

interface OptaFixturesApiResponse {
  SoccerFeed: {
    SoccerDocument: {
      MatchData: OptaFixturesMatchData[]
      Team: OptaFixturesTeamData[]
    }
  }
}

const extractChannelsFromOptaFeed = (matchData: OptaFixturesMatchData[]): ChannelWithMeta[] => {
  const venuesAsChannels: ChannelWithMeta[] = []
  matchData.forEach((match) => {
    if (
      !venuesAsChannels.find(
        (venueAsChannel) => venueAsChannel.uuid === match.MatchInfo['@attributes'].Venue_id,
      )
    ) {
      const venue = match.Stat.find((stat) => stat['@attributes'].Type === 'Venue')?.['@value']
      const city = match.Stat.find((stat) => stat['@attributes'].Type === 'City')?.['@value']

      const channelDataWithLogo = channels.find(
        (channelData) => channelData.code === match.MatchInfo['@attributes'].Venue_id,
      )
      if (channelDataWithLogo) {
        venuesAsChannels.push({
          uuid: match.MatchInfo['@attributes'].Venue_id,
          title: `${city} - ${venue}`,
          logo:
            (channelDataWithLogo && channelDataWithLogo.logo) ||
            require('../components/ChannelIcon/assets/7plus.png'),
          color: channelDataWithLogo.color,
          city: channelDataWithLogo.city,
          stadium: channelDataWithLogo.stadium,
          secondaryColor: channelDataWithLogo.secondaryColor,
        })
      }
    }
  })
  return venuesAsChannels
}

const getMatchDescription = (matchData: OptaFixturesMatchData) => {
  const venue = matchData.Stat.find((stat) => stat['@attributes'].Type === 'Venue')?.['@value']
  const city = matchData.Stat.find((stat) => stat['@attributes'].Type === 'City')?.['@value']
  switch (matchData.MatchInfo['@attributes'].RoundType) {
    case 'Round':
      return `Group Matches Group ${matchData.MatchInfo['@attributes'].GroupName} - ${venue}`
    default:
      return `${matchData.MatchInfo['@attributes'].RoundType} - ${venue}`
  }
}

const mapMatchDataToEpg = (
  matchData: OptaFixturesMatchData,
  teamData: OptaFixturesTeamData[],
): EpgSchedule => {
  const homeTeam = teamData.find(
    (team) => team['@attributes'].uID === matchData.TeamData[0]['@attributes'].TeamRef,
  )
  const awayTeam = teamData.find(
    (team) => team['@attributes'].uID === matchData.TeamData[1]['@attributes'].TeamRef,
  )

  const startDate = parseISO(matchData.MatchInfo.Date.replace(' ', 'T') + '+01:00')
  const endDate =
    matchData.MatchInfo['@attributes'].RoundNumber === '1'
      ? addMinutes(startDate, 120)
      : addMinutes(startDate, 200)
  return {
    id: matchData['@attributes'].uID,
    description: getMatchDescription(matchData),
    title:
      homeTeam && awayTeam
        ? `${homeTeam.Name.replace(' Women', '')} v ${awayTeam.Name.replace(' Women', '')}`
        : 'Unknown',
    isYesterday: false,
    since: startDate.toISOString(),
    till: endDate.toISOString(),
    channelUuid: matchData.MatchInfo['@attributes'].Venue_id,
    image: '',
  }
}

const extractEpgFromOptaFixturesData = (
  optaFixturesMatchData: OptaFixturesMatchData[],
  optaFixturesTeamData: OptaFixturesTeamData[],
) => {
  const epgData = optaFixturesMatchData.map((matchData) => mapMatchDataToEpg(matchData, optaFixturesTeamData))
  return epgData
}

const fetchOptaFixturesData = async (): Promise<OptaFixturesApiResponse> => {
  const response = await fetch('https://7news.com.au/fifa-wwc/fixtures')
  const jsonBody = await response.json()
  return jsonBody
}

// API Url format: https://api.birmingham2022.com/cwg-schedule/v1/sessions?sessionDate=2022-08-05&size=200
export const fetchChannels = async (startDate: string): Promise<ChannelWithMeta[]> => {
  const dateFromString = parseISO(startDate)
  const optaFixturesData = await fetchOptaFixturesData()
  // all channels
  const channelData = extractChannelsFromOptaFeed(optaFixturesData.SoccerFeed.SoccerDocument.MatchData)

  // all matches
  const epgData = extractEpgFromOptaFixturesData(
    optaFixturesData.SoccerFeed.SoccerDocument.MatchData,
    optaFixturesData.SoccerFeed.SoccerDocument.Team,
  )
  // add hasEventForCurrentDate meta for ChannelIcon
  const epgDataFiltered = epgData.filter((match) => isSameDay(parseISO(match.since), dateFromString))
  const channelDataUpdated = channelData.map((channel) => {
    const isMatchInEpgForCurrentDay = epgDataFiltered.find((match) => match.channelUuid === channel.uuid)
    channel.hasEventForCurrentDate = isMatchInEpgForCurrentDay ? true : false
    return channel
  })
  return channelDataUpdated
}

export const fetchEpg = async (startDate: string): Promise<EpgSchedule[]> => {
  const dateFromString = parseISO(startDate)
  const optaFixturesData = await fetchOptaFixturesData()
  const epgData = extractEpgFromOptaFixturesData(
    optaFixturesData.SoccerFeed.SoccerDocument.MatchData,
    optaFixturesData.SoccerFeed.SoccerDocument.Team,
  )
  const epgDataFiltered = epgData.filter((match) => {
    return isSameDay(parseISO(match.since), dateFromString)
  })

  return epgDataFiltered
}

export const TIME_FORMAT = {
  DATE: 'yyyy-MM-dd',
  HOURS_MIN: 'HH:mm',
  BASE_HOURS_TIME: 'h:mm a',
}

export const formatTimeInto12Hours = (time: string) => {
  const currentDate = new Date()
  const baseDateFormatted = format(currentDate, TIME_FORMAT.DATE)
  const baseDateWithTime = new Date(`${baseDateFormatted}T${time}:00`)
  const timeFormat = format(baseDateWithTime, TIME_FORMAT.BASE_HOURS_TIME)
  return timeFormat.toLowerCase().replace(/\s/g, '')
}

// formats "2022/08/05" into "2022-08-05" (ISO date format)
export const formatStartDate = (startDate: string) => {
  return startDate.replaceAll('/', '-')
}

export const isMobileScreenSize = () => {
  const screenWidth = window.screen.width * window.devicePixelRatio
  if (screenWidth <= 768 * window.devicePixelRatio) {
    return true
  } else {
    return false
  }
}

export type Placement = '7news' | '7plus'

export const getPlacement = (): Placement => {
  const urlParams = new URLSearchParams(window.location.search)
  const placement = urlParams.get('placement')
  switch (placement) {
    case '7plus':
      return '7plus'
    case '7news':
    default:
      return '7news'
  }
}

export const getTimeOfFirstEvent = (events: EpgSchedule[], currentSelectedDate: string) => {
  events.sort((eventA: EpgSchedule, eventB: EpgSchedule) => {
    const dateA = new Date(eventA.since)
    const dateB = new Date(eventB.since)
    return dateA.getTime() - dateB.getTime()
  })
  events = events.filter((event) => event.title !== 'filler')
  if (!isSameDay(parseISO(formatStartDate(currentSelectedDate)), new Date(events[0].since))) {
    // return start of current selected day if first event is carried over from previous day
    return `${formatStartDate(currentSelectedDate)}T00:00:00`
  }
  return events[0].since
}

/**
 * Sort channels based on event time and if event for current day present in the data
 * @param channels
 * @returns
 */
export const sortChannels = (channels: ChannelWithMeta[], epg: EpgSchedule[]) => {
  const sortedEvents = epg.sort((a, b) => new Date(a.since).getTime() - new Date(b.since).getTime())
  const sortedBasedOnEventTime = channels.sort(
    (a, b) =>
      sortedEvents.findIndex((event) => event.channelUuid === a.uuid) -
      sortedEvents.findIndex((event) => event.channelUuid === b.uuid),
  )
  const sortedByEventsInCurrentDay = sortedBasedOnEventTime.sort(
    (a, b) => Number(b.hasEventForCurrentDate) - Number(a.hasEventForCurrentDate),
  )

  return sortedByEventsInCurrentDay
}

const getFillerBlock = (
  channelCode: string,
  startTime: string,
  endTime: string,
  isFirstFillerBlock: boolean = false,
) => {
  if (isFirstFillerBlock) {
    // fix to make filler blocks start visually at the same position as events caried over from previous day
    const adjustedStartTime = subMinutes(parseISO(startTime), 5)
    startTime = adjustedStartTime.toISOString()
  }
  const filler: EpgSchedule = {
    id: 'filler' + channelCode + startTime,
    title: 'filler',
    description: 'filler',
    channelUuid: channelCode,
    image: '',
    since: startTime,
    till: endTime,
  }
  return filler
}

export const addFillerBlocks = (programs: EpgSchedule[], channels: Channel[], eventDate: string) => {
  // get schedule start and end in current timezone
  const midnightStartOfDay = parse(`${eventDate} 12:01am`, 'yyyy-MM-dd h:mma', new Date())
  const midnightEndOfDay = parse(`${eventDate} 11:59pm`, 'yyyy-MM-dd h:mma', new Date())

  // add filler block for each channel
  channels.forEach((channel) => {
    const eventsForCurrentSportFiltered = programs.filter((el) => el.channelUuid === channel.uuid)
    const eventsForCurrentSport = eventsForCurrentSportFiltered.sort((eventA, eventB) => {
      const dateA = parseISO(eventA.since)
      const dateB = parseISO(eventB.since)
      if (dateA.getTime() > dateB.getTime()) {
        return 1
      } else {
        return -1
      }
    })

    if (eventsForCurrentSport.length === 0) {
      const filler = getFillerBlock(
        channel.uuid,
        midnightStartOfDay.toISOString(),
        midnightEndOfDay.toISOString(),
        true,
      )
      programs.push(filler)
    }

    if (eventsForCurrentSport.length === 1) {
      // add filler block before and after
      const firstFiller = getFillerBlock(
        channel.uuid,
        midnightStartOfDay.toISOString(),
        eventsForCurrentSport[0].since,
        true,
      )
      programs.push(firstFiller)
      const lastFiller = getFillerBlock(
        channel.uuid,
        eventsForCurrentSport[0].till,
        midnightEndOfDay.toISOString(),
      )
      programs.push(lastFiller)
    } else if (eventsForCurrentSport.length === 2) {
      // add filler block before first event
      const firstFiller = getFillerBlock(
        channel.uuid,
        midnightStartOfDay.toISOString(),
        eventsForCurrentSport[0].since,
        true,
      )
      programs.push(firstFiller)
      // add filler block between events
      const secondFiller = getFillerBlock(
        channel.uuid,
        eventsForCurrentSport[0].till,
        eventsForCurrentSport[1].since,
      )
      programs.push(secondFiller)
      // add filler block after second event
      const thirdFiller = getFillerBlock(
        channel.uuid,
        eventsForCurrentSport[1].till,
        midnightEndOfDay.toISOString(),
      )
      programs.push(thirdFiller)
    } else if (eventsForCurrentSport.length === 3) {
      // add filler block before first event
      const firstFiller = getFillerBlock(
        channel.uuid,
        midnightStartOfDay.toISOString(),
        eventsForCurrentSport[0].since,
        true,
      )
      programs.push(firstFiller)
      // add filler block between events
      const secondFiller = getFillerBlock(
        channel.uuid,
        eventsForCurrentSport[0].till,
        eventsForCurrentSport[1].since,
      )
      programs.push(secondFiller)
      // add filler block between events
      const thirdFiller = getFillerBlock(
        channel.uuid,
        eventsForCurrentSport[1].till,
        eventsForCurrentSport[2].since,
      )
      programs.push(thirdFiller)
      // add filler block after third event
      const fourthFiller = getFillerBlock(
        channel.uuid,
        eventsForCurrentSport[2].till,
        midnightEndOfDay.toISOString(),
      )
      programs.push(fourthFiller)
    } else if (eventsForCurrentSport.length === 4) {
      // add filler block before first event
      const firstFiller = getFillerBlock(
        channel.uuid,
        midnightStartOfDay.toISOString(),
        eventsForCurrentSport[0].since,
        true,
      )
      programs.push(firstFiller)
      // add filler block between events
      const secondFiller = getFillerBlock(
        channel.uuid,
        eventsForCurrentSport[0].till,
        eventsForCurrentSport[1].since,
      )
      programs.push(secondFiller)
      // add filler block between events
      const thirdFiller = getFillerBlock(
        channel.uuid,
        eventsForCurrentSport[1].till,
        eventsForCurrentSport[2].since,
      )
      programs.push(thirdFiller)
      // add filler block between events
      const fourthFiller = getFillerBlock(
        channel.uuid,
        eventsForCurrentSport[2].till,
        eventsForCurrentSport[3].since,
      )
      programs.push(fourthFiller)
      // add filler block after last event
      const fifthFiller = getFillerBlock(
        channel.uuid,
        eventsForCurrentSport[3].till,
        midnightEndOfDay.toISOString(),
      )
      programs.push(fifthFiller)
    } else if (eventsForCurrentSport.length === 5) {
      // add filler block before first event
      const firstFiller = getFillerBlock(
        channel.uuid,
        midnightStartOfDay.toISOString(),
        eventsForCurrentSport[0].since,
        true,
      )
      programs.push(firstFiller)
      // add filler block between events
      const secondFiller = getFillerBlock(
        channel.uuid,
        eventsForCurrentSport[0].till,
        eventsForCurrentSport[1].since,
      )
      programs.push(secondFiller)
      const thirdFiller = getFillerBlock(
        channel.uuid,
        eventsForCurrentSport[1].till,
        eventsForCurrentSport[2].since,
      )
      programs.push(thirdFiller)
      const fourthFiller = getFillerBlock(
        channel.uuid,
        eventsForCurrentSport[2].till,
        eventsForCurrentSport[3].since,
      )
      programs.push(fourthFiller)
      const fifthFiller = getFillerBlock(
        channel.uuid,
        eventsForCurrentSport[3].till,
        eventsForCurrentSport[4].since,
      )
      programs.push(fifthFiller)
      // add filler block after last event
      const sixthFiller = getFillerBlock(
        channel.uuid,
        eventsForCurrentSport[4].till,
        midnightEndOfDay.toISOString(),
      )
      programs.push(sixthFiller)
    }
  })

  return programs
}
