export const theme7plus = {
  primary: {
    600: '#1a202c',
    900: '#000000',
  },
  // required but unused
  grey: { 300: '#d1d1d1' },

  green: { 300: '#33333399' },
  // required but unused
  scrollbar: {
    border: '#ffffff',
    thumb: {
      bg: '#e1e1e1',
    },
  },
  // required but unused
  gradient: {
    blue: {
      300: '#002eb3',
      600: '#002360',
      900: '#051937',
    },
  },
  text: {
    grey: {
      300: '#fff',
      500: '#718096',
    },
  },
  rowOfDays: {
    primary: '#2c2c2c',
    secondary: '#ffffff',
    text: '#ffffff',
    textSecondary: '#2c2c2c',
    background: '#000000',
  },
  eventBox: {
    primary: '#2C2C2C',
    primaryHover: '#ffffff33',
    text: '#ffffff',
    textDescription: 'rgba(255, 255, 255, 0.7)',
    fillerBackground: '#1f1f1f',
    fillerText: 'rgba(255, 255, 255, 0.6)',
  },
  timelineScrubber: {
    primary: '#E6001E',
    thumbText: '#fff',
    track: '#FFFFFF33',
    helpText: '#FFFFFF66',
  },
  timelineDivider: '#ffffff1a',
  scrollbarThumb: '#e1e1e1aa',
  scrollDisclaimer: '#fff',
}

export const theme7news = {
  primary: {
    600: '#1a202c',
    900: '#ffffff',
  },
  // required but unused
  grey: { 300: '#d1d1d1' },

  green: { 300: '#33333399' },
  // required but unused
  scrollbar: {
    border: '#ffffff',
    thumb: {
      bg: '#e1e1e1',
    },
  },
  // required but unused
  gradient: {
    blue: {
      300: '#002eb3',
      600: '#002360',
      900: '#051937',
    },
  },
  text: {
    grey: {
      300: '#333333',
      500: '#718096',
    },
  },
  rowOfDays: {
    primary: '#F1F1F1',
    secondary: '#292A33',
    text: '#333333',
    textSecondary: '#ffffff',
    background: '#ffffff',
  },
  eventBox: {
    primary: '#F1F1F1',
    primaryHover: '#2c2c2c1a',
    text: '#333333',
    textDescription: 'rgba(51, 51, 51, 0.7)',
    fillerBackground: '#fafafa',
    fillerText: 'rgba(51, 51, 51, 0.6)',
  },
  timelineScrubber: {
    primary: '#333333',
    thumbText: '#fff',
    track: '#33333333',
    helpText: '#33333333',
  },
  timelineDivider: '#3333331a',
  scrollbarThumb: '#e1e1e1',
  scrollDisclaimer: '#999999',
}
