import { Placement } from './common'

export type MatchUrlData = {
  matchId: string
  url: string
}

export const matchLinks7News: MatchUrlData[] = [
  // group stage
  {
    matchId: 'g2260571',
    url: 'https://7plus.com.au/fifa-womens-world-cup-2023?utm_source=7NEWS&utm_medium=schedule&utm_campaign=nz-vs-nor-fifa-wwc-23',
  },
  {
    matchId: 'g2260577',
    url: 'https://7plus.com.au/fifa-womens-world-cup-2023?utm_source=7NEWS&utm_medium=schedule&utm_campaign=aus-vs-ire-fifa-wwc-23',
  },
  {
    matchId: 'g2260579',
    url: 'https://7plus.com.au/fifa-womens-world-cup-2023?utm_source=7NEWS&utm_medium=schedule&utm_campaign=can-vs-ire-fifa-wwc-23',
  },
  {
    matchId: 'g2260580',
    url: 'https://7plus.com.au/fifa-womens-world-cup-2023?utm_source=7NEWS&utm_medium=schedule&utm_campaign=aus-vs-nigeria-fifa-wwc-23',
  },
  {
    matchId: 'g2260581',
    url: 'https://7plus.com.au/fifa-womens-world-cup-2023?utm_source=7NEWS&utm_medium=schedule&utm_campaign=aus-vs-can-fifa-wwc-23',
  },
  {
    matchId: 'g2260593',
    url: 'https://7plus.com.au/fifa-womens-world-cup-2023?utm_source=7NEWS&utm_medium=schedule&utm_campaign=china-vs-eng-fifa-wwc-23',
  },

  // round of 16 stage
  {
    matchId: 'g2260620',
    url: 'https://7plus.com.au/fifa-womens-world-cup-2023?utm_source=7NEWS&utm_medium=schedule&utm_campaign=japan-vs-norway-rd16-fifa-wwc-23',
  },
  {
    matchId: 'g2260622',
    url: 'https://7plus.com.au/fifa-womens-world-cup-2023?utm_source=7NEWS&utm_medium=schedule&utm_campaign=group-g-winner-vs-group-e-2nd-rd16-fifa-wwc-23',
  },
  {
    matchId: 'g2260623',
    url: 'https://7plus.com.au/fifa-womens-world-cup-2023?utm_source=7NEWS&utm_medium=schedule&utm_campaign=aus-vs-den-rd16-fifa-wwc-23',
  },
  {
    matchId: 'g2260625',
    url: 'https://7plus.com.au/fifa-womens-world-cup-2023?utm_source=7NEWS&utm_medium=schedule&utm_campaign=group-f-winner-vs-group-h-2nd-rd16-fifa-wwc-23',
  },

  // quarter finals
  {
    matchId: 'g2260628',
    url: 'https://7plus.com.au/fifa-womens-world-cup-2023?utm_source=7plus&utm_medium=schedule&utm_campaign=jap-vs-swe-quarterfinal-fifa-wwc-23',
  },
  {
    matchId: 'g2260629',
    url: 'https://7plus.com.au/fifa-womens-world-cup-2023?utm_source=7plus&utm_medium=schedule&utm_campaign=aus-vs-rd16-7-winner-quarterfinal-fifa-wwc-23',
  },

  // semi finals
  {
    matchId: 'g2260631',
    url: 'https://7plus.com.au/fifa-womens-world-cup-2023?utm_source=7NEWS&utm_medium=schedule&utm_campaign=semi-final-1-fifa-wwc-23',
  },
  {
    matchId: 'g2260632',
    url: 'https://7plus.com.au/fifa-womens-world-cup-2023?utm_source=7NEWS&utm_medium=schedule&utm_campaign=semi-final-2-fifa-wwc-23',
  },
  // 3rd and 4th place
  {
    matchId: 'g2260633',
    url: 'https://7plus.com.au/fifa-womens-world-cup-2023?utm_source=7NEWS&utm_medium=schedule&utm_campaign=third-place-fifa-wwc-23',
  },
  // final
  {
    matchId: 'g2260634',
    url: 'https://7plus.com.au/fifa-womens-world-cup-2023?utm_source=7NEWS&utm_medium=schedule&utm_campaign=final-fifa-wwc-23',
  },
  // default
  {
    matchId: 'default',
    url: 'https://sport.optus.com.au/womens-world-cup-2023?utm_source=7NEWS&utm_medium=referral&utm_campaign=fifa-womens-world-cup-2023',
  },
]

export const matchLinks7Plus: MatchUrlData[] = [
  // group stage
  {
    matchId: 'g2260571',
    url: 'https://7plus.com.au/fifa-womens-world-cup-2023?utm_source=7plus&utm_medium=schedule&utm_campaign=nz-vs-nor-fifa-wwc-23',
  },
  {
    matchId: 'g2260577',
    url: 'https://7plus.com.au/fifa-womens-world-cup-2023?utm_source=7plus&utm_medium=schedule&utm_campaign=aus-vs-ire-fifa-wwc-23',
  },
  {
    matchId: 'g2260579',
    url: 'https://7plus.com.au/fifa-womens-world-cup-2023?utm_source=7plus&utm_medium=schedule&utm_campaign=can-vs-ire-fifa-wwc-23',
  },
  {
    matchId: 'g2260580',
    url: 'https://7plus.com.au/fifa-womens-world-cup-2023?utm_source=7plus&utm_medium=schedule&utm_campaign=aus-vs-nigeria-fifa-wwc-23',
  },
  {
    matchId: 'g2260581',
    url: 'https://7plus.com.au/fifa-womens-world-cup-2023?utm_source=7plus&utm_medium=schedule&utm_campaign=aus-vs-can-fifa-wwc-23',
  },
  {
    matchId: 'g2260593',
    url: 'https://7plus.com.au/fifa-womens-world-cup-2023?utm_source=7plus&utm_medium=schedule&utm_campaign=china-vs-eng-fifa-wwc-23',
  },

  // round of 16 stage
  {
    matchId: 'g2260620',
    url: 'https://7plus.com.au/fifa-womens-world-cup-2023?utm_source=7plus&utm_medium=schedule&utm_campaign=japan-vs-norway-rd16-fifa-wwc-23',
  },
  {
    matchId: 'g2260622',
    url: 'https://7plus.com.au/fifa-womens-world-cup-2023?utm_source=7plus&utm_medium=schedule&utm_campaign=group-g-winner-vs-group-e-2nd-rd16-fifa-wwc-23',
  },
  {
    matchId: 'g2260623',
    url: 'https://7plus.com.au/fifa-womens-world-cup-2023?utm_source=7plus&utm_medium=schedule&utm_campaign=aus-vs-den-rd16-fifa-wwc-23',
  },
  {
    matchId: 'g2260625',
    url: 'https://7plus.com.au/fifa-womens-world-cup-2023?utm_source=7plus&utm_medium=schedule&utm_campaign=group-f-winner-vs-group-h-2nd-rd16-fifa-wwc-23',
  },

  // quarter finals
  {
    matchId: 'g2260628',
    url: 'https://7plus.com.au/fifa-womens-world-cup-2023?utm_source=7plus&utm_medium=schedule&utm_campaign=jap-vs-swe-quarterfinal-fifa-wwc-23',
  },
  {
    matchId: 'g2260629',
    url: 'https://7plus.com.au/fifa-womens-world-cup-2023?utm_source=7plus&utm_medium=schedule&utm_campaign=aus-vs-rd16-7-winner-quarterfinal-fifa-wwc-23',
  },

  // semi finals
  {
    matchId: 'g2260631',
    url: 'https://7plus.com.au/fifa-womens-world-cup-2023?utm_source=7plus&utm_medium=schedule&utm_campaign=semi-final-1-fifa-wwc-23',
  },
  {
    matchId: 'g2260632',
    url: 'https://7plus.com.au/fifa-womens-world-cup-2023?utm_source=7plus&utm_medium=schedule&utm_campaign=semi-final-2-fifa-wwc-23',
  },
  // 3rd and 4th place
  {
    matchId: 'g2260633',
    url: 'https://7plus.com.au/fifa-womens-world-cup-2023?utm_source=7plus&utm_medium=schedule&utm_campaign=third-place-fifa-wwc-23',
  },
  // final
  {
    matchId: 'g2260634',
    url: 'https://7plus.com.au/fifa-womens-world-cup-2023?utm_source=7plus&utm_medium=schedule&utm_campaign=final-fifa-wwc-23',
  },
  // default
  {
    matchId: 'default',
    url: 'https://sport.optus.com.au/womens-world-cup-2023?utm_source=7plus&utm_medium=referral&utm_campaign=fifa-womens-world-cup-2023',
  },
]

export const getLinkUrlFromMatch = (matchId: string, placement: Placement = '7news') => {
  if (placement === '7news') {
    const sportData = matchLinks7News.find((el) => el.matchId === matchId)
    if (sportData) {
      return sportData.url
    } else {
      return 'https://sport.optus.com.au/womens-world-cup-2023?utm_source=7NEWS&utm_medium=referral&utm_campaign=fifa-womens-world-cup-2023'
    }
  } else {
    const sportData = matchLinks7Plus.find((el) => el.matchId === matchId)
    if (sportData) {
      return sportData.url
    } else {
      return 'https://sport.optus.com.au/womens-world-cup-2023?utm_source=7plus&utm_medium=referral&utm_campaign=fifa-womens-world-cup-2023'
    }
  }
}
