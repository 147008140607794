import { ChannelWithPosiiton } from 'planby/dist/Epg/helpers/types'
import { getPlacement } from '../../helpers'
import {
  StyledChannelBox,
  StyledChannelCity,
  StyledChannelStadium,
  StyledEmptyDisclaimer,
  StyledInnerChannelBox,
} from './ChannelIcon.styled'

type ChannelIconProps = {
  channel: ChannelWithPosiiton
}

export const ChannelIcon = ({ channel }: ChannelIconProps) => {
  const { position, color, hasEventForCurrentDate } = channel

  return (
    <StyledChannelBox {...position}>
      <StyledInnerChannelBox color={color} placement={getPlacement()}>
        <StyledChannelCity>{channel.city}</StyledChannelCity>
        <StyledChannelStadium>{channel.stadium}</StyledChannelStadium>
      </StyledInnerChannelBox>
      {hasEventForCurrentDate ? null : <StyledEmptyDisclaimer>No matches today</StyledEmptyDisclaimer>}
    </StyledChannelBox>
  )
}
