import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Schedule from './components/Schedule/Schedule'
import RowOfDays from './components/RowOfDays/RowOfDays'
import TimelineScrubber from './components/TimelineScrubber/TimelineScrubber'
import { StyledAppContainer } from './App.styled'
import {
  addFillerBlocks,
  Channel,
  ChannelWithMeta,
  EpgSchedule,
  fetchChannels,
  fetchEpg,
  formatStartDate,
  getPlacement,
  getTimeOfFirstEvent,
  Placement,
  sortChannels,
} from './helpers'
import { ThemeProvider } from '@emotion/react'
import { theme7news, theme7plus } from './helpers/theme'

const getTheme = (placement: Placement) => {
  switch (placement) {
    case '7plus':
      return theme7plus
    case '7news':
    default:
      return theme7news
  }
}

function App() {
  const [startDate, setStartDate] = useState<string>('2023/07/20')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [scrollBoxRef, setScrollBoxRef] = useState<React.RefObject<HTMLDivElement>>()

  const [channels, setChannels] = useState<ChannelWithMeta[]>([] as ChannelWithMeta[])
  const [epg, setEpg] = useState<EpgSchedule[]>([] as EpgSchedule[])
  const [firstEventOfDay, setFirstEventOfDay] = useState<string>('')

  const handleFetchResources = useCallback(async () => {
    setIsLoading(true)
    const epg = await fetchEpg(formatStartDate(startDate))
    const channels = sortChannels(await fetchChannels(formatStartDate(startDate)), epg)
    const epgWithFillerBlocks = addFillerBlocks(epg, channels, formatStartDate(startDate))

    setEpg(epgWithFillerBlocks)
    if (epg.length > 0) {
      const firstEvent = getTimeOfFirstEvent(epg, startDate)
      setFirstEventOfDay(firstEvent)
    }
    setChannels(channels)
    setIsLoading(false)
  }, [startDate])

  useEffect(() => {
    handleFetchResources()
  }, [handleFetchResources])

  return (
    <ThemeProvider theme={getTheme(getPlacement())}>
      <StyledAppContainer>
        <RowOfDays selectedDate={startDate} setSelectedDate={setStartDate} />
        <TimelineScrubber
          isLoading={isLoading}
          scrollBoxRef={scrollBoxRef}
          firstEventOfDay={firstEventOfDay}
        />
        <Schedule
          startDate={startDate}
          isLoading={isLoading}
          setScrollBoxRef={setScrollBoxRef}
          epg={epg}
          channels={channels}
        />
      </StyledAppContainer>
    </ThemeProvider>
  )
}

export default App
