import styled from '@emotion/styled'
import { Swiper, SwiperSlide } from 'swiper/react'

export const StyledRowOfDaysContainer = styled('div')(({ theme }) => ({
  marginBottom: '12px',
}))

export const StyledDisclaimer = styled('div')(({ theme }) => ({
  color: theme.scrollDisclaimer,
  fontSize: '10px',
  fontWeight: '300',
  display: 'flex',
  justifyContent: 'flex-end',
}))

export interface StyledDayButtonProps {
  isSelected?: boolean
}

export const StyledDayButton = styled('span')<StyledDayButtonProps>(({ isSelected, theme }) => ({
  background: isSelected ? theme.rowOfDays.secondary : theme.rowOfDays.primary,
  color: isSelected ? theme.rowOfDays.textSecondary : theme.rowOfDays.text,
  borderRadius: '1px',
  fontSize: '11px',
  maxHeight: '32px',
  padding: '8px 18px',
  fontWeight: 500,
  cursor: 'pointer',
}))

export const StyledSwiperSlide = styled(SwiperSlide)(({ theme }) => ({
  width: 'auto',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))

export const StyledSwiper = styled(Swiper)(({ theme }) => ({}))
