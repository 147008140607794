import styled from '@emotion/styled'

export const StyledScrubberContainer = styled('div')(() => ({
  height: '30px',
  position: 'relative',
  marginBottom: '10px',
}))
export const StyledScrubberTrack = styled('div')(({ theme }) => ({
  height: '3px',
  background: theme.timelineScrubber.track,
  width: '100%',
  position: 'absolute',
  top: '16px',
}))

export interface StyledScrubberThumbProps {
  percentageValue: string
}
export const StyledScrubberThumb = styled('div')<StyledScrubberThumbProps>(({ percentageValue, theme }) => ({
  background: theme.timelineScrubber.primary,
  width: '108px',
  height: '32px',
  left: percentageValue,
  position: 'relative',
  borderRadius: '1px',
  cursor: 'grab',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-evenly',

  '&:active': {
    cursor: 'grabbing',
  },
  top: '1px',
  zIndex: 999,
}))

export const StyledScrubberThumbTime = styled('span')(({ theme }) => ({
  fontWeight: 600,
  fontSize: '12px',
  userSelect: 'none',
  color: theme.timelineScrubber.thumbText,
  top: '1px',
  position: 'relative',
}))

export const StyledScrubberThumbImage = styled('img')(() => ({
  height: '15px',
  userSelect: 'none',
  position: 'relative',
  top: '1px',
}))

export const StyledScrubberHelp = styled('span')(({ theme }) => ({
  position: 'absolute',
  right: '4px',
  color: theme.timelineScrubber.helpText,
  fontSize: '12px',
  top: 0,
  fontWeight: 500,
  display: 'none',
  '@media(min-width: 1024px)': {
    display: 'block',
  },
}))
