import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import './fonts/heywow/HeyWow-Light.otf'
import './fonts/heywow/HeyWow-Light.ttf'
import './fonts/heywow/HeyWow-Regular.otf'
import './fonts/heywow/HeyWow-Regular.ttf'
import './fonts/heywow/HeyWow-Medium.otf'
import './fonts/heywow/HeyWow-Medium.ttf'
import './fonts/heywow/HeyWow-SemiBold.otf'
import './fonts/heywow/HeyWow-SemiBold.ttf'
import './fonts/heywow/HeyWow-Bold.otf'
import './fonts/heywow/HeyWow-Bold.ttf'
import './fonts/heywow/HeyWow-ExtraBold.otf'
import './fonts/heywow/HeyWow-ExtraBold.ttf'
import './fonts/heywow/HeyWow-Heavy.otf'
import './fonts/heywow/HeyWow-Heavy.ttf'

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
