import { useTimeline } from 'planby'
import { formatTimeInto12Hours } from '../../helpers'
import {
  StyledTimelineBox,
  StyledTimelineDivider,
  StyledTimelineTime,
  StyledTimelineWrapper,
} from './Timeline.styled'

interface TimelineProps {
  isBaseTimeFormat: boolean
  isSidebar: boolean
  dayWidth: number
  hourWidth: number
  numberOfHoursInDay: number
  offsetStartHoursRange: number
  sidebarWidth: number
}

const TIME_FORMAT = {
  DATE: 'yyyy-MM-dd',
  BASE_HOURS_TIME: 'h:mm a',
}

export function Timeline({
  isBaseTimeFormat, // if true convert to 12 hour format
  isSidebar,
  dayWidth,
  hourWidth,
  numberOfHoursInDay,
  offsetStartHoursRange,
  sidebarWidth,
}: TimelineProps) {
  const { time, dividers } = useTimeline(numberOfHoursInDay, isBaseTimeFormat)

  const myFormatTime = (index: number) => {
    const wholeHour = Math.floor(index / 2)
    const hours = wholeHour < 10 ? `0${wholeHour}` : wholeHour
    const minutes = index % 2 === 0 ? '00' : '30'

    const time = `${hours}:${minutes}`

    if (isBaseTimeFormat) {
      return formatTimeInto12Hours(time)
    } else {
      return time
    }
  }

  const myHourWidth = hourWidth / 2
  const myTime = new Array(time.length * 2).fill('')

  const renderTime = (index: number) => (
    <StyledTimelineBox key={index} width={myHourWidth}>
      <StyledTimelineTime>{myFormatTime(index + offsetStartHoursRange).toLowerCase()}</StyledTimelineTime>
    </StyledTimelineBox>
  )

  return (
    <StyledTimelineWrapper dayWidth={dayWidth} sidebarWidth={sidebarWidth} isSidebar={isSidebar}>
      <StyledTimelineDivider />
      {myTime.map((_, index) => renderTime(index))}
    </StyledTimelineWrapper>
  )
}
