import styled from '@emotion/styled'
import { ChannelBox, ChannelLogo } from 'planby'
import { Placement } from '../../helpers'

export const StyledChannelBox = styled(ChannelBox)(() => ({
  padding: '2px 0',
  paddingTop: '2px',
  paddingBottom: '3px',
}))

export const StyledChannelLink = styled('a')(() => ({
  width: '100%',
  height: '100%',
}))

export interface StyledInnerChannelBoxProps {
  color: string
  placement: Placement
}
export const StyledInnerChannelBox = styled('div')<StyledInnerChannelBoxProps>(({ color, placement }) => ({
  backgroundColor: color,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  width: '100%',
  transition: 'all 0.3s ease-in-out',
  flexDirection: 'column',
  overflow: 'hidden',
  boxShadow: '0 0 25px rgba(0,0,0,0.4)',
  clipPath: 'inset(0px -25px 0px 0px)',
}))

export const StyledChannelCity = styled('div')(({ theme }) => ({
  textAlign: 'center',
  fontSize: '12px',
  letterSpacing: '-0.4px',
  fontWeight: 900,
  textTransform: 'uppercase',
  userSelect: 'none',
  color: theme.text.grey[300],
}))

export const StyledChannelStadium = styled('div')(({ theme }) => ({
  textAlign: 'center',
  fontSize: '10px',
  fontWeight: 400,
  textTransform: 'uppercase',
  width: '80%',
  color: theme.text.grey[300],
  userSelect: 'none',
}))

export const StyledEmptyDisclaimer = styled('div')(({ theme }) => ({
  position: 'absolute',
  fontSize: '11px',
  fontWeight: 300,
  color: theme.text.grey[300],
  opacity: '0.5',
  left: '104px',
  width: '140px',
  userSelect: 'none',
}))

export interface StyledChannelLogoProps {}
export const StyledChannelLogo = styled(ChannelLogo)<StyledChannelLogoProps>(() => ({
  maxHeight: '62px',
  height: 'auto',
  maxWidth: '91px',
  width: 'auto',
  padding: '8px',
}))
