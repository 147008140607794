import styled from '@emotion/styled'

export const StyledScheduleContainer = styled('div')(({ theme }) => ({
  height: '888px',
  width: '100%',
  margin: '0 auto',
  // container
  '>div': {
    padding: 0,
    // wrapper
    '>div': {
      borderRadius: 0,
      // scrollbox
      '>div:nth-child(2)': {
        marginTop: '-24px',
        zIndex: '99999',
        '&::-webkit-scrollbar': { height: 0 },
        '&::-webkit-scrollbar-thumb': {
          background: theme.scrollbarThumb,
        },
        '>:nth-child(1):empty': {
          width: '2px',
        },
      },
    },
  },
}))

export const StyledLoaderContainer = styled('div')(() => ({
  backgroundColor: '#333333B3',
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: '0',
  left: '0',
  bottom: '0',
}))
