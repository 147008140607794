import { formatStartDate, getPlacement } from '../../helpers'
import { StyledDayButton, StyledDisclaimer, StyledRowOfDaysContainer, StyledSwiper } from './RowOfDays.styled'
import { isToday, parseISO, format } from 'date-fns'
import { useEffect } from 'react'
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react'
import 'swiper/css'

const getEventDateForToday = () => {
  const currentDay = new Date()
  const currentDateFormatted = format(currentDay, 'yyyy/MM/dd').replace(/\s/g, '')
  return currentDateFormatted
}

const eventDays = [
  {
    dateLabel: '20 July',
    dateValue: '2023/07/20',
  },
  {
    dateLabel: '21 July',
    dateValue: '2023/07/21',
  },
  {
    dateLabel: '22 July',
    dateValue: '2023/07/22',
  },
  // test date that is always current day
  // {
  //   dateLabel: 'Day X - July XX',
  //   dateValue: getEventDateForToday(),
  // },
  {
    dateLabel: '23 July',
    dateValue: '2023/07/23',
  },
  {
    dateLabel: '24 July',
    dateValue: '2023/07/24',
  },
  {
    dateLabel: '25 July',
    dateValue: '2023/07/25',
  },
  {
    dateLabel: '26 July',
    dateValue: '2023/07/26',
  },
  {
    dateLabel: '27 July',
    dateValue: '2023/07/27',
  },
  {
    dateLabel: '28 July',
    dateValue: '2023/07/28',
  },
  {
    dateLabel: '29 July',
    dateValue: '2023/07/29',
  },
  {
    dateLabel: '30 July',
    dateValue: '2023/07/30',
  },
  {
    dateLabel: '31 July',
    dateValue: '2023/07/31',
  },
  {
    dateLabel: '01 August',
    dateValue: '2023/08/01',
  },
  {
    dateLabel: '02 August',
    dateValue: '2023/08/02',
  },
  {
    dateLabel: '03 August',
    dateValue: '2023/08/03',
  },
  {
    dateLabel: '05 August',
    dateValue: '2023/08/05',
  },
  {
    dateLabel: '06 August',
    dateValue: '2023/08/06',
  },
  {
    dateLabel: '07 August',
    dateValue: '2023/08/07',
  },
  {
    dateLabel: '08 August',
    dateValue: '2023/08/08',
  },
  {
    dateLabel: '11 August',
    dateValue: '2023/08/11',
  },
  {
    dateLabel: '12 August',
    dateValue: '2023/08/12',
  },
  {
    dateLabel: '15 August',
    dateValue: '2023/08/15',
  },
  {
    dateLabel: '16 August',
    dateValue: '2023/08/16',
  },
  {
    dateLabel: '19 August',
    dateValue: '2023/08/19',
  },
  {
    dateLabel: '20 August',
    dateValue: '2023/08/20',
  },
]

const getCurrentDayFromListOfEventDays = () => {
  const today = eventDays.find((el) => {
    const dateFormatted = formatStartDate(el.dateValue)
    return isToday(parseISO(dateFormatted))
  })
  return today
}

const getIndexOfCurrentDate = () => {
  const currentDay = getCurrentDayFromListOfEventDays()
  if (currentDay) {
    const currentDayIndex = eventDays.findIndex((el) => {
      return el.dateValue === currentDay.dateValue
    })
    return currentDayIndex
  }
}

type RowOfDaysProps = {
  selectedDate: string
  setSelectedDate: React.Dispatch<React.SetStateAction<string>>
}

const RowOfDays = (props: RowOfDaysProps) => {
  const { selectedDate, setSelectedDate } = props
  const handleButtonClick = (e: React.MouseEvent<HTMLElement>) => {
    const target = e.target as HTMLButtonElement
    setSelectedDate(target.dataset.value as string)
  }

  useEffect(() => {
    // navigate to current day if it's part of the schedule
    const currentDay = getCurrentDayFromListOfEventDays()
    if (currentDay) {
      setSelectedDate(currentDay.dateValue)
      // update label to include 'Today'
      const index = eventDays.findIndex((el) => {
        return el.dateValue === currentDay.dateValue
      })
      if (index) {
        eventDays[index].dateLabel = 'Today - ' + eventDays[index].dateLabel
      }
    }
  }, [])

  return (
    <StyledRowOfDaysContainer>
      <StyledDisclaimer>*Swipe to scroll</StyledDisclaimer>
      <StyledSwiper
        slidesPerView={'auto'}
        onSwiper={(swiper) => {
          const currentDateIndex = getIndexOfCurrentDate()
          if (currentDateIndex) {
            if (currentDateIndex > 0) {
              swiper.slideTo(currentDateIndex - 1)
            } else {
              swiper.slideTo(0)
            }
          }
        }}
        slideToClickedSlide={true}
        // fixes issue where unable to scroll to last slide
        slidesOffsetAfter={140}
      >
        {eventDays.map((el, index) => (
          <SwiperSlide
            key={index}
            style={{ backgroundColor: getPlacement() === '7plus' ? '#000000' : 'initial' }}
          >
            <StyledDayButton
              onClick={handleButtonClick}
              data-value={el.dateValue}
              isSelected={selectedDate === el.dateValue}
            >
              {el.dateLabel}
            </StyledDayButton>
          </SwiperSlide>
        ))}
      </StyledSwiper>
    </StyledRowOfDaysContainer>
  )
}

RowOfDays.displayName = 'RowOfDays'

export default RowOfDays
