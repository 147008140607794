export const channels = [
  {
    uuid: '8364',
    title: 'Auckland - Eden Park',
    code: '8364',
    logo: require('../components/ChannelIcon/assets/auckland.png'),
    color: '#FFC500',
    secondaryColor: '#f8efd5',
    city: 'Auckland',
    stadium: 'EDEN PARK, NZL',
  },
  {
    uuid: '3824',
    title: 'Sydney - Allianz Stadium',
    code: '3824',
    logo: require('../components/ChannelIcon/assets/sydney.png'),
    color: '#FA8200',
    secondaryColor: '#f6e2d1',
    city: 'Sydney',
    stadium: 'ALLIANZ STADIUM, AUS',
  },
  {
    uuid: '9523',
    title: 'Dunedin - Dunedin Stadium',
    code: '9523',
    logo: require('../components/ChannelIcon/assets/dunedin.png'),
    color: '#FD006A',
    secondaryColor: '#efcbd6',
    city: 'Dunedin',
    stadium: 'DUNEDIN STADIUM,NZL',
  },
  {
    uuid: '6678',
    title: 'Melbourne - AAMI Park',
    code: '6678',
    logo: require('../components/ChannelIcon/assets/melbourne.png'),
    color: '#BD0068',
    secondaryColor: '#eacedc',
    city: 'Melbourne',
    stadium: 'AAMI PARK, AUS',
  },
  {
    uuid: '2140',
    title: 'Wellington - Sky Stadium',
    code: '2140',
    logo: require('../components/ChannelIcon/assets/wellington.png'),
    color: '#18CC2C',
    secondaryColor: '#daefd6',
    city: 'Wellington',
    stadium: 'SKY STADIUM, NZL',
  },
  {
    uuid: '10526',
    title: 'Hamilton - Waikato Stadium',
    code: '10526',
    logo: require('../components/ChannelIcon/assets/hamilton.png'),
    color: '#18872B',
    secondaryColor: '#d4e2d2',
    city: 'Hamilton',
    stadium: 'WAIKATO STADIUM, NZL',
  },
  {
    uuid: '3865',
    title: 'Brisbane - Suncorp Stadium',
    code: '3865',
    logo: require('../components/ChannelIcon/assets/brisbane.png'),
    color: '#00ECDA',
    secondaryColor: '#ddf5f3',
    city: 'Brisbane',
    stadium: 'SUNCORP STADIUM, AUS',
  },
  {
    uuid: '3832',
    title: 'Perth - HBF Park',
    code: '3832',
    logo: require('../components/ChannelIcon/assets/perth.png'),
    color: '#00BCC8',
    secondaryColor: '#d8ecef',
    city: 'Perth',
    stadium: 'HBF Park, AUS',
  },
  {
    uuid: '2978',
    title: 'Adelaide - Coopers Stadium',
    code: '2978',
    logo: require('../components/ChannelIcon/assets/adelaide.png'),
    color: '#FFC500',
    secondaryColor: '#f8efd5',
    city: 'Adelaide',
    stadium: 'COOPERS STADIUM, AUS',
  },
  {
    uuid: '2122',
    title: 'Sydney - Stadium Australia',
    code: '2122',
    logo: require('../components/ChannelIcon/assets/sydney2.png'),
    color: '#FA8200',
    secondaryColor: '#f6e2d1',
    city: 'Sydney',
    stadium: 'STADIUM AUSTRALIA, AUS',
  },
]

export const getPrimaryColorFromChannel = (uuid: string) => {
  const channel = channels.find((el) => el.uuid === uuid)
  if (channel) {
    return channel.color
  } else {
    return '#ffffff'
  }
}

export const getSecondaryColorFromChannel = (uuid: string) => {
  const channel = channels.find((el) => el.uuid === uuid)
  if (channel) {
    return channel.secondaryColor
  } else {
    return '#ffffff'
  }
}
